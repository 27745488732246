<template>
  <section id="student" v-if="student">
    <h1 id="page-title"><router-link :to="roleLinkAppendix()+'/students'"><BackSvg class="mr-3"/></router-link>{{ student.name }}</h1>
    <b-row class="row-wide">
      <b-col md="auto">
        <div class="spacecard left-sideblock sm spacecard-menu text-center student-card">
          <LetterIcon :obj="student" :title="student.name" class="student-icon lg ml-auto mr-auto"/>
          <b-button class="d-block svg-icon-wrapper edit-btn btn-themed btn-white position-absolute" @click="edit">
            <SettingsSvg class=""/>
          </b-button>
          <div class="content-under-photo">
            <div class="sp-bolder mt-4">{{ student.name }}</div>
            <div class="text-muted small text-capitalize">{{ student.level }}</div>
            <div class="top-spacing">
              <b-row>
                <!--                            <b-col cols="12 mb-3">-->
                <!--                                <div class="gray-block">-->
                <!--                                    <div class="text-muted">{{ $t('lessons') }}</div>-->
                <!--                                    <div></div>-->
                <!--                                </div>-->
                <!--                            </b-col>-->
                <b-col cols="12">
                  <div class="gray-block">
                    <div class="text-muted">{{ $t('tariff') }}</div>
                    <div>{{ student.student_price_policy ? student.student_price_policy.title_price : '-' }}</div>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div class="top-spacing">
              <SelectGroup class="default"
                           :floatinglabel="true"
                           v-model="status"
                           :options="statuses"
                           :label="$t('status')"/>
            </div>
            <div class="text-left top-spacing">
              <div class="sp-bolder">{{ $t('note') }}<EditSvg class="ml-2 pointer" @click="addNote"/></div>
              <div class="text-muted small">{{ student.note ? student.note : '-' }}</div>
            </div>
            <div class="top-spacing">
              <router-link class="btn-themed" :to="{ name: roleId == 5 ? 'SchoolSchedule' : 'Schedule', query: { students: student.id }}">
                {{ $t('schedule') }}
              </router-link>
            </div>
          </div>
        </div>
        <div v-if="alfaData" class="spacecard left-sideblock sm spacecard-menu text-center mt-3">
          <div class="sp-bolder mt-2 mb-3">Alfa CRM</div>
          <div class="text-left">{{ $t('balance') }}: {{ alfaData.balance }}</div>
        </div>
      </b-col>
      <b-col md>
        <div class="tabs-navigator lg mt-lg-0 mt-3">
          <router-link class=""
                       v-for="(m,mind) in menu"
                       :to="{ name: m.name, params: { id: id } }"
                       :key="'menu-p-'+mind">{{ $t(m.title) }}
          </router-link>
        </div>
        <div class="spacecard min-h-card">
          <router-view :student="student" @refresh="getProfile"/>
        </div>
      </b-col>
    </b-row>
    <AddStudentModal @refresh="getProfile"
                     v-if="studentmodal"
                     :student="student"/>
  </section>
</template>

<script>
// import AuthService from "@/services/auth.helper"
import { StudentsService } from '@/services/api.service'
import BackSvg from '@/assets/svg/back.svg'
import EditSvg from '@/assets/svg/pen2.svg'
import SettingsSvg from '@/assets/svg/settings.svg'
import SelectGroup from "@/views/parts/general/form/SelectGroup"
import LetterIcon from '@/views/parts/general/LetterIcon'
import AddStudentModal from '@/views/pages/instructor/students/AddStudentModal'

export default {
  components: {
    BackSvg,
    EditSvg,
    SettingsSvg,
    SelectGroup,
    LetterIcon,
    AddStudentModal
  },
  data() {
    return {
      teacher_menu: [
        { name: "StudentHistory", title: "history" },
        { name: "StudentPayments", title: "payments" },
        { name: "StudentSelfStudy", title: "selfstudy" },
        { name: "StudentLight", title: "light" },
      ],
      school_menu: [
        { name: "SchoolStudentHistory", title: "history" },
        { name: "SchoolStudentPayments", title: "payments" },
      ],
      statuses: [
        { id: 'active', title: 'active' },
        { id: 'paused', title: 'paused' },
        { id: 'archived', title: 'archived' },
      ],
      id: this.$route.params.id,
      student: null,
      status: null,
      studentmodal: false,
    }
  },
  mounted() {
    this.getProfile()
  },
  methods: {
    getProfile() {
      StudentsService.getStudent(this.id).then(res => {
        this.student = res.data.data
        this.status = this.student.student_status
      })
    },
    addNote() {
      this.$input(this.$t('student_note'),this.$t('note'),this.student.note).then((val) => {
        StudentsService.updateNote(this.student.id, val).then(() => {
          this.student.note = val
        })
      })
    },
    edit() {
      this.studentmodal = true
      this.$nextTick(() => {
        this.$bvModal.show('addstudentmodal')
      })
    }
  },
  watch: {
    status(val, prev) {
      if(prev) {
        StudentsService.updateStatus(this.id, val).then(() => {
          this.student.student_status = val
        })
      }
    }
  },
  computed: {
    roleId() {
      return this.$store.state.user ? this.$store.state.user.primary_role_id : 2;
    },
    menu() {
      if([4,5].includes(this.roleId)) {
        return this.school_menu
      }
      return this.teacher_menu
    },
    alfaData() {
      if(!this.student) return null
      return this.student.studentAlfaData
    }
  },
  metaInfo() {
    return {
      title: this.student?.name || this.$t('student_profile')
    }
  },
}
</script>

<style scoped lang="scss">
.student-card {
  margin-top: 56px;
  .top-spacing {
    margin-top: 24px;
  }
  .student-icon {
    width: 120px;
    height: 120px;
    border: 3px solid #FFFFFF;
    filter: drop-shadow(0px 4px 4px rgba(105, 114, 121, 0.25));
    top: -70px;
    position: relative;
  }
  .gray-block {
    font-size: 14px;
    padding: 12px;
    height: 100%;
    background: $gray-back2;
    border-radius: 12px;
    text-align: left;
  }
  .edit-btn {
    right: 30px;
    top: 70px;
  }
  .content-under-photo {
    margin-top: -70px;
  }
}
</style>
